<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height" v-if="!isLoading">
        <template v-if="orderRatings && Object.keys(orderRatings.data).length > 0">
        <template v-for="(data, index) in orderRatings.data">
          <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in" :key="index"
          >
          <b-col md="12">
            <b-card no-body>
              <b-row>
                <b-col md="9" cols="8">
                  <b-card-body>
                    <b-card-title>{{data.order.username}}</b-card-title>
                    <ul class="unstyled-list list-inline mb-25">
                      <li
                        v-for="star in 5"
                        :key="star"
                        class="ratings-list-item"
                      >
                        <feather-icon
                          icon="StarIcon"
                          size="18"
                          class="mr-25"
                          :class="[{'fill-current': star <= data.rating}, star <= data.rating ? 'text-warning' : 'text-muted']"
                        />
                      </li>
                    </ul>
                    <b-row class="m-0">
                      {{data.comment}}
                    </b-row>
                  </b-card-body>
                </b-col>
                <b-col md="3" cols="4" align-self="center" class="text-center">
                  <!-- <b-button
                    variant="outline-primary"
                    class="btn-icon"
                    :to="`/order-ratings/${data.id}`"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button> -->
                  <b-form-checkbox
                    :id="`v-approved-`+data.id"
                    class="custom-control-primary"
                    v-model="data.approved"
                    name="approved"
                    switch
                    @change="changeApproval($event, data.id)"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          </transition>
        </template>
        </template>
        <template v-else>
          <b-col>
            <b-card class="text-center">
                <span>{{$t('content not found')}}.</span>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <pagination v-if="orderRatings" :limit="25" :data="orderRatings" @pagination-change-page="getOrderRatings"></pagination>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
  name: 'OrderRatings',
  data() {
    return {
      orderRatings: null,
      isLoading: false,
    }
  },
  mounted() {
    this.getOrderRatings();
  },
  methods: {
    getOrderRatings(page = 1){
      this.isLoading = true
      let data = {
          params: {
              page: page,
          }
      }
      useJwt.get('/order-ratings/get-all',data)
      .then((response) => {
        // console.log(response.data)
        this.orderRatings = response.data.data
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    changeApproval(event, id) {
      console.log(event)
      let form = {
          id: id,
          approved: event == true ? 1 : 0
      }
      useJwt.post('/order-ratings/approval',form)
      .then((response) => {
        console.log(response.data)
        // this.orderRatings = response.data.data
        // this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    }
  }
}
</script>
